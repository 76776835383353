<template>
  <dark-circles-template
    :dark-circles="darkCircles"
    :dark-circles-ai-selected="darkCirclesAiSelected"
    :dark-circle-colors="darkCircleColors"
    :dark-circle-colors-visible="darkCircleColorsVisible"
    :dark-circle-colors-ai-selected="darkCircleColorsAiSelected"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import DarkCirclesTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-appearance/dark-circles/DarkCirclesTemplate';

import { makeStep, mapAiSelectedFields, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { yes } from '@/modules/questionnaire/api/helpers';

const { requiredField, requiredArrayField } = fieldBuilder;

const FIELDS_TO_RESET = ['darkCircleColors'];

const AI_SELECTED_FIELDS = ['darkCircles', 'darkCircleColors'];

const FIELDS = [requiredField('darkCircles'), requiredArrayField('darkCircleColors')];

export default {
  name: 'DarkCircles',
  components: { DarkCirclesTemplate },
  mixins: [makeStep(FIELDS), mapAiSelectedFields(AI_SELECTED_FIELDS), resetStoreValues],
  computed: {
    darkCircleColorsVisible() {
      return yes(this.darkCircles);
    }
  },
  watch: {
    darkCircles(newValue) {
      if (yes(newValue)) {
        this.scrollTo('#dark-circle-colors');
        return;
      }

      this.resetStoreFieldsByName(FIELDS_TO_RESET);
      this.showNextStep();
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'darkCircleColors') {
        return this.darkCircleColorsVisible;
      }

      return true;
    }
  }
};
</script>
